import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom"
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import { Button, Modal, Form, ButtonGroup, Dropdown, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { IState } from '../store/interfaces/state';
import { useSelector } from 'react-redux';
import { Accordion, Paper } from 'reactify-ui';
import { autoHandles } from '../utils/utils';

const ParticipantsPage = (props: any) => {
	const data: IState = useSelector((state: any) => state.data);

    const [timer, setTimer] = useState('00:00:00:00');

    const Ref = useRef(null);

    const startTime = 1693238400000;
    const msInDay = 1000 * 60 * 60 * 24;
    const startTimes: [number, string][] = [
        [startTime, 'Play-In']
        , [startTime + (msInDay * 7), 'Week 1']
        , [startTime + (msInDay * 7 * 2), 'Week 2']
        , [startTime + (msInDay * 7) * 3, 'Week 3']
    ]

    useEffect(() => {
        startTimer();
    }, []);

    const getTimeRemaining = () => {
        const t = new Date().getTime();
        // const total = Date.parse(e) - Date.parse((new Date()).toString());
        const nextStartTimes = startTimes.filter(x => x[0] >= t);
        let total = 0;
        if (nextStartTimes.length) {
            const tmp = nextStartTimes.map(x => x[0] - t)
            total = nextStartTimes.map(x => x[0] - t).reduce((a, b) => Math.min(a, b), msInDay * 100);
        }

        // const total = Date.parse(e) - Date.parse((new Date()).toString());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        const days = Math.floor((total / msInDay));
        return {
            total, days, hours, minutes, seconds
        };
    }

    const updateTimer = () => {
        let { total, days, hours, minutes, seconds } = getTimeRemaining();
        if (total >= 0) {
            setTimer(
                (days > 9 ? days : '0' + days) + ':' +
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }
  
  
    const startTimer = () => {
        if (Ref.current) clearInterval(Ref.current);
        updateTimer();
        const id = setInterval(() => {
            updateTimer();
        }, 1000)
        // @ts-ignore
        Ref.current = id;
    }

    const registeredUsers = data.registeredUsers.filter( x => !x.hidden ).map(x => {
        const autoQualify = autoHandles.includes(x.flipsideUsername.toLowerCase()) || autoHandles.includes(x.twitterUsername.toLowerCase());
        return(
            <div className='row'>
                <div className='col'>
                    <a target='_blank' href={`http://flipsidecrypto.xyz/${x.flipsideUsername}`}>
                        {x.flipsideUsername}
                        {/* { autoQualify ? '*' : '' } */}
                    </a>
                </div>
                <div className='col'><a target='_blank' href={`http://twitter.com/${x.twitterUsername}`}>@{x.twitterUsername}</a></div>
            </div>
        )
    })
	return (
        <div className='participants-page'>
            <div className='timer'>
                <div className='big-text'>{timer}</div>
                <div className='small-text'>{`Time until Play-In begins`}</div>
            </div>
            <div className='participants-panel'>
                {/* <div style={{'fontSize': '12px', 'color':'rgba(255, 255, 255, 0.5)'}}>* indicates an auto-qualifier by scoring 1,000+ in the previous <a style={{'color': 'inherit'}} target='_blank' href='https://science.flipsidecrypto.xyz/tournament/'>tournament</a></div> */}
                <div className='participants-panel-header'>
                    <div className='row'>
                        <div className='col'>Flipside User</div>
                        <div className='col'>Twitter Handle</div>
                    </div>
                </div>
                <div className='participants-panel-body'>
                    {registeredUsers}
                </div>
            </div>
        </div>
	);
}

export default ParticipantsPage;