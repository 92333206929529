import { combineReducers } from 'redux';
import { IState } from '../interfaces/state';
import { SET_DASHBOARDS, SET_REGISTERED_USERS, SET_TEAM_POINTS, SET_USER_POINTS } from '../types/types';

export const INITIAL_STATE: IState = {
    'dashboards': []
    , 'userPoints': []
    , 'teamPoints': []
    , 'registeredUsers': []
};

const reducer = (state: IState = INITIAL_STATE, action: any): IState => {
    
    switch (action.type) {
        case SET_DASHBOARDS:
            return {
                ...state,
                dashboards: action.data
            }
        case SET_USER_POINTS:
            return {
                ...state,
                userPoints: action.data
            }
        case SET_TEAM_POINTS:
            return {
                ...state,
                teamPoints: action.data
            }
        case SET_REGISTERED_USERS:
            return {
                ...state,
                registeredUsers: action.data
            }
        default: return state;
    }
}

const rootReducer = combineReducers({
    data: reducer,
})
export default rootReducer;