
export class UserPoints {

    round_name: string;
    user_id: string;
    flipside_handle: string;
    team_name: string;
    twitter_handle: string;
    points: number;
    auto: number;

	constructor(
        round_name: string = ''
        , user_id: string = ''
        , flipside_handle: string = ''
        , team_name: string = ''
        , twitter_handle: string = ''
        , points: number = 0
        , auto: number = 0
    ) {
        this.round_name = round_name;
        this.user_id = user_id;
        this.flipside_handle = flipside_handle;
        this.team_name = team_name;
        this.twitter_handle = twitter_handle;
        this.points = points;
        this.auto = auto;
    }

}