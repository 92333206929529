import { Dashboard } from "../../models/Dashboard";
import { IRegisteredUser } from "../../models/RegisteredUser";
import { TeamPoints } from "../../models/TeamPoints";
import { UserPoints } from "../../models/UserPoints";
import * as types from "../types/types";

export const setDashboards = (dashboards: Dashboard[]) => {
    return {
        type: types.SET_DASHBOARDS,
        data: dashboards
    };
};

export const setRegisteredUsers = (registeredUsers: IRegisteredUser[]) => {
    return {
        type: types.SET_REGISTERED_USERS,
        data: registeredUsers
    };
};

export const setUserPoints = (userPoints: UserPoints[]) => {
    return {
        type: types.SET_USER_POINTS,
        data: userPoints
    };
};

export const setTeamPoints = (teamPoints: TeamPoints[]) => {
    return {
        type: types.SET_TEAM_POINTS,
        data: teamPoints
    };
};
