'use strict';

import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import LeftBar from './components/LeftBar';
import { isMobile, onlyUnique } from './utils/utils';
import RegisterPanel from './components/RegisterPanel';
import RulesPage from './components/RulesPage';
import PrizePage from './components/PrizePage';
import ScorePage from './components/ScorePage';
// import * as snowflake from 'snowflake-sdk';
// import * as fs from 'fs';
import dashboardsJson from './data/scores.json';

import { BACKEND_URL, SNOWFLAKE_ACCOUNT, SNOWFLAKE_PASSWORD, SNOWFLAKE_ROLE, SNOWFLAKE_USER, SNOWFLAKE_WAREHOUSE } from './constants/constants';
import { readFileSync } from 'fs';
import { useDispatch } from 'react-redux';
// const fs = require('fs');
import * as actions from './store/actions/actions';
import { Dashboard } from './models/Dashboard';
import { UserPoints } from './models/UserPoints';
import { TeamPoints } from './models/TeamPoints';
import HomePage from './components/HomePage';
import TopBar from './components/TopBar';
import axios from 'axios';
import { IRegisteredUser } from './models/RegisteredUser';
import ParticipantsPage from './components/ParticipantsPage';
import SchedulePage from './components/SchedulePage';
import ScoringPage from './components/ScoringPage';
import StandingsPage from './components/StandingsPage';
import AboutPage from './components/AboutPage';
import SponsorPage from './components/SponsorPage';

// import { configure, createConnection } from 'snowflake-sdk';

function App() {
	const dispatch = useDispatch();
	const dashboards: Dashboard[] = JSON.parse(dashboardsJson);

	const loadRegisteredUsers = async () => {
		let response = await axios({
			method: 'get',
			url: BACKEND_URL+'/api/user/registeredUsers',
		});
		console.log(`loadRegisteredUsers response`);
		console.log(response);
		let registeredUsers: IRegisteredUser[] = response.data;
		dispatch(actions.setRegisteredUsers(registeredUsers.sort((a, b) => b.timestamp - a.timestamp )));
	}
	const loadData = async () => {
		console.log(`loadData dashboards`);
		console.log(dashboards.filter(x => x.user_id == '08b215df-7b48-4e5c-9814-95435c9dc289'));

		// usage example:
		// const uniqueUserIds = dashboards.map( x => x.user_id).filter(onlyUnique);
		const uniqueFlipsideHandles = dashboards.map( x => x.flipside_handle).filter(onlyUnique);
		console.log(`uniqueFlipsideHandles`);
		console.log(uniqueFlipsideHandles);
		const uniqueRounds = dashboards.filter(x => x.round_name).map( x => x.round_name).filter(onlyUnique);
		uniqueRounds.push('Overall')
		const uniqueTeams = dashboards.filter(x => x.team_name != 'Quizzical Quokkas').map( x => x.team_name).filter(onlyUnique);
		const userPoints: UserPoints[] = []
		const teamPoints: TeamPoints[] = []
		const scoreDict: any = {};
		for (let i = 0; i < uniqueFlipsideHandles.length; i++) {
			const user: Dashboard = dashboards.filter(x => x.flipside_handle == uniqueFlipsideHandles[i])[0];
			if (user.flipside_handle == 'rezarwz') {
				console.log(`rezarwz user`);
				console.log(user);
			}
			for (let j = 0; j < uniqueRounds.length; j++) {
				const curDashboards = dashboards.filter(x => x.flipside_handle == uniqueFlipsideHandles[i] && ((x.round_name == uniqueRounds[j] || (x.round_name != 'Play-In' && uniqueRounds[j] == 'Overall'))))
				const points = curDashboards.reduce((a, b) => a + b.points, 0);
				// const example = dashboards.filter
				if ((uniqueRounds[j] == 'Play-In' || curDashboards.length > 0)) {
					const u = new UserPoints(
						uniqueRounds[j]
						, uniqueFlipsideHandles[i]
						, user.flipside_handle
						, user.team_name
						, user.twitter_handle
						, points
						, user.auto
					)
					userPoints.push(u)
				}
			}
		}
		for (let i = 0; i < uniqueTeams.length; i++) {
			for (let j = 0; j < uniqueRounds.length; j++) {
				const rd = uniqueRounds[j];
				if (rd == 'Play-In') {
					continue;
				}
				const points = userPoints.filter(x => x.team_name == uniqueTeams[i] && x.round_name == uniqueRounds[j]).sort((a, b) => b.points - a.points);
				let curTeamPoints = points.slice(0, 3).reduce((a, b) => a + b.points, 0);
				if (rd == 'Overall') {
					curTeamPoints = teamPoints.filter(x => x.team_name == uniqueTeams[i]).reduce((a, b) => a + b.points, 0);
				}
				console.log(`rd = ${rd}`);
				console.log(`points`);
				console.log(points);
				if (points.length) {
					const t = new TeamPoints(
						uniqueRounds[j]
						, points[0].team_name
						, curTeamPoints
					)
					teamPoints.push(t);
				}
			}
		}
		dispatch(actions.setDashboards(dashboards));
		dispatch(actions.setUserPoints(userPoints.sort((a, b) => b.points == a.points ? (b.flipside_handle.toLowerCase() > a.flipside_handle.toLowerCase() ? -1 : 1 ) : b.points - a.points)));
		dispatch(actions.setTeamPoints(teamPoints.sort((a, b) => b.points == a.points ? (b.team_name.toLowerCase() > a.team_name.toLowerCase() ? -1 : 1 ) : b.points - a.points)));
		// dispatch(actions.setTeamPoints(teamPoints.sort((a, b) => b.points - a.points)));

		// const data = fs.readFileSync('./data/scores.json', 'utf8');
		// const result = readFileSync('./data/scores.json', {
		// 	encoding: 'utf-8',
		//   });
		//   console.log(result);
		// console.log(`data`);
		// console.log(data);
	// 	const snowConnect = createConnection({
	// 		account: SNOWFLAKE_ACCOUNT,
	// 		username: SNOWFLAKE_USER, 
	// 		password: SNOWFLAKE_PASSWORD,
	// 		authenticator: "SNOWFLAKE", 
	// 		warehouse: SNOWFLAKE_WAREHOUSE,
	// 		role: SNOWFLAKE_ROLE, // Create a role for API calls
	// 		clientSessionKeepAlive: true,
	// 	});
	// 	configure({ ocspFailOpen: false });
	// 	console.log(`snowConnect`);
	// 	console.log(snowConnect);
	}

    useEffect(() => {
		loadData();
		loadRegisteredUsers();
		const writeKey = '2U51jifjSjFPGXapbQPUSzSAWFS';
		const url = 'https://rsp-production.flipsidecrypto.workers.dev';
		if (writeKey && url) {
			// configUrl and destSDKBaseURL are overriding the default values, we are sending those requests through cloudflare
			try {
				window.rudderanalytics.load(writeKey, url, { configUrl: url, destSDKBaseURL: url + "/integrations" });
				window.rudderanalytics.page('app-category', 'app-name');
			} catch {
				
			}
		}
    }, []);


	return (
		<BrowserRouter>
			<div className='App'>
				<div className='row'>
        			<div className='col-12' style={{'padding': '0'}}>
						<TopBar />
							<div className='fpl-container'>
								{/* <div className='row' style={{'paddingTop': '15px', 'maxWidth': '1100px', 'margin': '0 auto'}}> */}
								{/* <div className='main'> */}
									{/* <div className='row'> */}
										{/* <div className={`col middle-col`}> */}
												<Routes>
													<Route path='/' element={<HomePage />} />
													<Route path='/about' element={<AboutPage />} />
													<Route path='/faq' element={<RulesPage />} />
													{/* <Route path='/prizes' element={<PrizePage />} /> */}
													<Route path='/score' element={<ScorePage />} />
													{/* <Route path='/rules' element={<ScoringPage />} /> */}
													<Route path='/participants' element={<ParticipantsPage />} />
													<Route path='/sponsors' element={<SponsorPage />} />
													{/* <Route path='/schedule' element={<SchedulePage />} /> */}
													{/* <Route path='/standings' element={<StandingsPage />} /> */}
													{/* <Route path="/register" element={<RegisterPanel />} /> */}
													<Route path="*" element={<HomePage />} />
												</Routes>
											{/* {
												useAddress == '' ? <MainPage />
												:
												<Routes>
													<Route path='/' element={<MainPage />} />
													<Route path='/expeditions' element={<ExpeditionsPage />} />
													<Route path='/settings' element={<SettingsPage />} />
													<Route path='/leaderboard' element={<LeaderboardPage />} />
													<Route path='/frontier' element={<FrontierPage />} />
													<Route path='/about' element={<AboutPage />} />
													<Route path='/admin' element={<AdminPage />} />
													<Route path='/:program' element={<ProgramPage />} />
													<Route path="*" element={<MainPage />} />
												</Routes>
											} */}
										{/* </div> */}
									{/* </div> */}
							</div>
						</div>
					</div>
					{/* </div> */}
				{/* </div> */}
			</div>
		</BrowserRouter>
	);
}

export default App;
