
const width = (window.innerWidth > 0) ? window.innerWidth : window.screen.height;
export const isMobile = width < 768;

export const startTime: number = 1692633600000;
export const msInDay = 1000 * 60 * 60 * 24;

export const startTimes: [number, string][] = [
    [startTime, 'Play-In']
    , [startTime + (msInDay * 7), 'Week 1']
    , [startTime + (msInDay * 7 * 2), 'Week 2']
    , [startTime + (msInDay * 7) * 3, 'Week 3']
]

export const autoHandles = [
	'fknmarqu','marqu','h4wk10','h4wk','CryptoEdgar_','crypto_edgar','adriaparcerisas','mrfti_plus','mrfti','0xtelperion','telperion','bg_alt','jackthepine','jackguy','Deebs_Defi','Deebs-DeFi-I-Orbital-Command-j9fRbz','zakkisyed','smyyguy','dan-smith','mosi115','mo115','Diamond','DiamondNFLAD'
].map(x => x.toLowerCase());

export const onlyUnique = (value: any, index: number, array: any[]) => {
	return array.indexOf(value) === index;
}

export const getTime = () => {
	// return((new Date().getTime()) + (1000 * 60 * 60 * 24 * 1));
	return((new Date().getTime()))
}

export const ordinal_suffix_of = (i: number) => {
    const j = i % 10, k = i % 100;
    if (j == 1 && k != 11) {
        return(i + 'st');
    }
    if (j == 2 && k != 12) {
        return(i + 'nd');
    }
    if (j == 3 && k != 13) {
        return(i + 'rd');
    }
    return(i + 'th');
}

export const prepend = (value: any, array: any[]) => {
	const newArray = array.slice();
	newArray.unshift(value);
	return(newArray);
  }