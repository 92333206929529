
export class TeamPoints {

    round_name: string;
    team_name: string;
    points: number;

	constructor(
        round_name: string = ''
        , team_name: string = ''
        , points: number = 0
    ) {
        this.round_name = round_name;
        this.team_name = team_name;
        this.points = points;
    }

}