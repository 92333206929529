
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { IState } from '../store/interfaces/state';
import { getTime, isMobile, startTime } from '../utils/utils';
import { Button, Modal, Form, ButtonGroup, Dropdown, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

const home = require('../assets/icons/trailhead.png');
// const admin = require('../assets/icons/admin.png');
// const leaderboard = require('../assets/icons/leaderboard.png');
// const settings = require('../assets/icons/tent.png');
// const compass = require('../assets/icons/compass.png');
// const about = require('../assets/icons/flashlight.png');
// const frontier = require('../assets/icons/frontier-icon.png');
const twitter = require('../assets/logos/twitter-logo.png');
const docs = require('../assets/logos/docs-icon-2.png');
const telegram = require('../assets/logos/telegram-icon-2.png');
const discord = require('../assets/logos/discord-blue.png');
const flipside = require('../assets/logos/flipside-blue.png');


const TopBar = (props: any) => {
	// const data: IState = useSelector((state: any) => state.data);
    const [ curPage, setCurPage ] = useState('About');
    const curTime = getTime();
    const data = [
        [ '', 'darts', 'Home' ]
        // , [ 'register', 'weight', 'Register' ]
        // , [ 'register', 'weight-01', 'Register' ]
        , [ 'about', 'score', 'About' ]
        , [ 'score', 'score', 'Score' ]
        // , [ 'participants', 'score', 'Participants' ]
        , [ 'sponsors', 'score', 'Sponsors' ]
        // , [ 'schedule', 'trophy', 'Schedule' ]
        // , [ 'prizes', 'trophy', 'Prizes' ]
        , [ 'faq', 'rules', 'FAQ' ]
    ].filter( x => 
        (!(['register','participants'].includes(x[0]))) || (curTime < (startTime + (1000 * 60 * 60 * 24 * 8)))
    );
    const options = data.map((x, i) => {
        return(
            
			<Dropdown.Item key={i} eventKey={i} onClick={() => {
                setCurPage(x[2])
			}}>
				<NavLink to={`/${x[0]}`}>{x[2]}</NavLink>
			</Dropdown.Item>
        );
    })
    
    const dropdown = 
        <Dropdown className='base-dropdown' as={ButtonGroup}>
            <Dropdown.Toggle id="week-dropdown">
                <span>{curPage}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="base-dropdown-menu">
                {options}
            </Dropdown.Menu>
        </Dropdown>
    const items = data.map(x => {
        const img = require(`../assets/icons/${x[1]}.png`);
        return(
            <div className='fpl-col'>
                <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to ={`/${x[0]}`}>
                    <div className='top-bar-item'>
                        <div className='top-bar-div'>
                            {/* <div className='top-bar-div-inner'>
                                <img className='top-bar-logo' alt='logo' src={String(img)} />
                            </div> */}
                            <div className='top-bar-text'>{isMobile ? '' : x[2]}</div>
                        </div>
                        {/* <div>{isMobile ? '' : x[2]}</div> */}
                    </div>
                </NavLink>
            </div>
        )
    });
	return (
        <div className='top-bar'>
            <div className='top-bar-list fpl-row'>
                {isMobile ? dropdown : items}
            </div>
        </div>
	);
}

export default TopBar;