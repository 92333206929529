
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';

const RulesPage = (props: any) => {
    const rows = [
        []
    ]
	return (
        <div className='faq-page'>
            {/* <div>
                About Us
            </div>
            <div>
                We are cool
            </div> */}
            <div>
                <MDBAccordion className='fpl-accordion' initialActive={1}>
                    <MDBAccordionItem collapseId={1} headerTitle={<span><span className='fpl-accordion-icon'>🏀</span>Play-In</span>}>
                        <ul>
                            <li>All analysts that register for the FPL will enter into the Play-In, which will last 7 days</li>
                            <li>The Play-In starts Monday, August 28th at 4pm UTC</li>
                            <li>40 total analysts will qualify for FPL season 2</li>
                            <li>The top 28 analysts from the Play-In will qualify for the Flipside Premier League</li>
                            <li>12 analysts from FPL Season 1 will auto-qualify</li>
                            <li>Top 30 from the Play-In earn between $5 and $250 each</li>
                        </ul>
                    </MDBAccordionItem>
                    <MDBAccordionItem collapseId={2} headerTitle={<span><span className='fpl-accordion-icon'>💯</span>Scoring</span>}>
                        <ul>
                            <li>Each dashboard receives points for every hour spent on the top 40 trending dashboards on <a href='http://flipsidecrypto.xyz/' target='_blank'>http://flipsidecrypto.xyz</a></li>
                            <li><span className='code'>Points = 41 - {'<current rank>'}</span>, accruing each hour for each dashboard</li>
                            <li>A dashboard's rank in the trending algorithm is based on views and likes</li>
                            <li>Learn more about the algorithmm <a href='https://docs.flipsidecrypto.com/our-app/analyst-rewards/top-8-dashboard-rewards' target='_blank'>here</a></li>
                            <li>Feel free to create as many dashboards as you'd like, but only your top 2 dashboards with the most points will count towards your overall score</li>
                        </ul>
                    </MDBAccordionItem>
                    <MDBAccordionItem collapseId={3} headerTitle={<span><span className='fpl-accordion-icon'>🤝</span>Teams</span>}>
                        <ul>
                            <li>After the play-in, analysts in the FPL will form 10 teams of 4</li>
                            <li>After the play-in, we will reach out to the 40 qualifying analysts to ask for any team preferences</li>
                            <li>Teams will be assembled the the Premier League commissioners (Kellen + GJ), though we will do our best to pair analysts that request to work together</li>
                            <li>We will strive to ensure balance between teams</li>
                            {/* <li>Each team will also have a "bench player": a 5th person on the squad that can fill in if a team member has to withdraw</li> */}
                        </ul>
                    </MDBAccordionItem>
                    <MDBAccordionItem collapseId={4} headerTitle={<span><span className='fpl-accordion-icon'>📅</span>Season</span>}>
                        <ul>
                            <li>The season will be 3 rounds long</li>
                            <li>Each round will last 7 days, starting and ending Mondays at 4pm UTC</li>
                            <li>There will also be a 7 day break in between each round</li>
                            <li>Scoring will be the same as the Play-In</li>
                            {/* <li>Scoring will be accumulated for the 3 weeks</li> */}
                            {/* <li>You will also get a "Rating" based on your place at the end of the week</li>
                            <li><span className='code'>Rating = 33 - {`<your place that week>`}</span></li>
                            <li>Analysts with 0 points will accrue a 0 Rating</li>
                            <li>This Rating will accrue throughout the season and the analysts with the 3 highest ratings at the end earn a prize</li>
                            <li>This Rating will also count towards your Team's Rating</li> */}
                        </ul>
                    </MDBAccordionItem>
                    <MDBAccordionItem collapseId={5} headerTitle={<span><span className='fpl-accordion-icon'>🏅</span>Individual Awards</span>}>
                        <ul>
                            <li>The top 30 analysts each week will earn rewards between $5 and $250, depending on place</li>
                            {/* <li>All analysts in the tournament also get a 1.5x multiplier on Top 8 payouts</li> */}
                            <li>The 3 individuals with the highest cumulative score at the end of the season earn a bonus of $500/$250/$100 respectively</li>
                        </ul>
                    </MDBAccordionItem>
                    <MDBAccordionItem collapseId={6} headerTitle={<span><span className='fpl-accordion-icon'>🏆</span>Team Awards</span>}>
                        <ul>
                            <li>Each week, teams will take the highest 3 individual scores and sum to a Team Score</li>
                            <li>This allows teams the flexibility such that if one team member has IRL commitments that week, the team can still succeed</li>
                            <li>The top 4 teams at the end of each week earn a $75/$50/$30/$20 prize each for the 4 analysts on the team</li>
                            <li>The top 4 teams at the end of the season earn a bonus $75/$50/$30/$20 prize each for the 4 analysts on the team</li>
                        </ul>
                    </MDBAccordionItem>
                    <MDBAccordionItem collapseId={8} headerTitle={<span><span className='fpl-accordion-icon'>❔</span>Still Have Questions?</span>}>
                        <ul>
                            <li>Join our <a target='_blank' href='https://discord.com/invite/ZmU3jQuu6W'>discord</a> and hop in the #premier-league channel!</li>
                        </ul>
                    </MDBAccordionItem>
                </MDBAccordion>
            </div>
        </div>
	);
}

export default RulesPage;