import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useParams } from "react-router-dom"
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import { Button, Modal, Form, ButtonGroup, Dropdown, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { IState } from '../store/interfaces/state';
import { useSelector } from 'react-redux';
import { Accordion, Paper } from 'reactify-ui';
import { getTime } from '../utils/utils';

const ScorePage = (props: any) => {
	const data: IState = useSelector((state: any) => state.data);

    const autoIds: string[] = [
        // '98a3c542-64ed-4f9a-b145-88569435a3f2'
        // , '1abe2020-72ce-4291-ac8f-c098a28c5877'
        // , 'e57d70cd-45e2-4d5b-9c65-80445176c2a0'
        // , '001dcab6-54ae-448f-80fb-b4d98303bd1e'
        // , '0c1d6f02-327a-4f6e-aed1-22d27c03a096'
        // , '503201de-0261-44cf-a79d-2140439a2d58'
        // , '4f1d8ac0-9107-40ed-bc44-c07b3c8ebcbe'
        // , 'fe5c3d2a-21e5-4ace-a435-c83611e34ad6'
        // , 'edab8ed3-f65a-4232-a616-30391ac111e3'
    ]

    const [ week, setWeek ] = useState( 'Week 3');
    const [ grouping, setGrouping ] = useState( 'Analyst');
    const places = Array.from(Array(20).keys())
    const prizes = places.map(x => ((33 - x) * 15) - 195);
    const mxPrize = prizes.reduce((a, b) => Math.max(a, b), 0);
    const isPlayIn = week == 'Play-In';

    const startTime = 1693238400000;
    const msInDay = 1000 * 60 * 60 * 24;
    const startTimes: [number, string][] = [
        [startTime, 'Play-In']
        , [startTime + (msInDay * 14), 'Week 1']
        , [startTime + (msInDay * 7 * 4), 'Week 2']
        , [startTime + (msInDay * 7 * 6), 'Week 3']
        , [startTime + (msInDay * 7 * 8), '']
    ]
    const showFilters = true;
     
    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);
  
    // The state for our timer
    // const [timer, setTimer] = useState('00:00:00:00');
    const [timer, setTimer] = useState('--');

    const userPoints = data.userPoints.filter(x => ((x.auto == 0) || !isPlayIn) && x.round_name == week).sort((a, b) => b.points - a.points);
    // let nQualified = 0, cutoff = 0;
    // for (let i = 0; i < userPoints.length; i++) {
    //     if (!autoIds.includes(userPoints[i].user_id)) {
    //         nQualified += 1;
    //         if (nQualified <= 23) {
    //             cutoff = i;
    //         }
    //     }
    // }
    const cutoff = 32;
    const getTeamImg = (team_name: string) => {
        const ext = team_name == 'Block Surfers' ? 'svg' : 'png';
        return(`${team_name.toLowerCase().replaceAll(' ', '') }.${ext}`);
    }
    const teamPoints = data.teamPoints.filter(x => x.round_name == week);
    const prizeAmounts = [250, 235, 220, 210, 200, 190, 180, 170, 160, 150, 140, 130, 120, 110, 100, 90, 80, 70, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5];
    const userRowsAccordion = userPoints.map((x, i) => {
        const auto = isPlayIn && autoIds.includes(x.user_id);
        const q = i <= cutoff || auto;
        const colorClass = q && isPlayIn ? 'orange-text' : '';
		const img = require(`../assets/avatars/${x.twitter_handle.toLowerCase()}.png`);
		const teamImg = require(`../assets/teams/${getTeamImg(x.team_name)}`);
        const title = <div className='row'>
            <div className='user-col col-1 col-lg-2'>{i+1}</div>
            <div className={`user-col col-7 col-lg-${isPlayIn ? 4 : 3}`}>
                <img className='avatar' src={String(img)}/>
                <span className='username'><span className={colorClass}>{x.flipside_handle}{auto ? '*' : ''}</span></span>
            </div>
            {
                isPlayIn ? null :
                <div className='user-col col col-lg-4'>
                    <div className='team-name'>
                        <img className='avatar' src={String(teamImg)}/>
                        <span>{x.team_name}</span>
                    </div>
                </div>
            }
            <div className='user-col col-2 col-lg-1'>{x.points.toLocaleString()}</div>
            <div className='user-col col-2 col-lg-1'>{ week != 'Overall' && i < prizeAmounts.length ? `$${prizeAmounts[i]}` : '-'}</div>
        </div>
        const dashboards = data.dashboards.filter(y => y.flipside_handle == x.flipside_handle && y.round_name == x.round_name).map(y => {
            return(
                <div className='row'>
                    <div className='col'><a target='_blank' href={y.dashboard_url}>{y.dashboard_title}</a></div>
                    <div className='col'>{y.score.toLocaleString()}</div>
                    <div className='col'>{y.multiplier}x</div>
                    <div className='col'>{y.points.toLocaleString()}</div>
                </div>
            )
        });
        return(

            <MDBAccordionItem className='user-row' collapseId={i+1} headerTitle={title}>
                <div className='row header-row'>
                    <div className='col'>Dashboard</div>
                    <div className='col'>Score</div>
                    <div className='col'>Multiplier</div>
                    <div className='col'>Points</div>
                </div>
                {dashboards}
            </MDBAccordionItem>
        )
    })
    const teamRowsAccordion = teamPoints.map((x, i) => {
		const teamImg = require(`../assets/teams/${getTeamImg(x.team_name)}`);
        const title = <div className='row'>
            <div className='user-col col-2 col-lg-1'>{i+1}</div>
            <div className='user-col col col-lg-7'>
                <div className='team-name'>
                    <img className='avatar' src={String(teamImg)}/>
                    <span>{x.team_name}</span>
                </div>
            </div>
            <div className='user-col col-2 col-lg-1'>{x.points.toLocaleString()}</div>
        </div>
        const dashboards = data.userPoints.filter(y => y.team_name == x.team_name && y.round_name == x.round_name).map((y, j) => {
            const multiplier = j <= 2 ? 1 : 0
            return(
                <div className='row'>
                    <div className='col'>{y.flipside_handle}</div>
                    <div className='col'>{y.points.toLocaleString()}</div>
                    <div className='col'>{multiplier}x</div>
                    <div className='col'>{(y.points * multiplier).toLocaleString()}</div>
                </div>
            )
        });
        return(

            <MDBAccordionItem className='user-row' collapseId={i+1} headerTitle={title}>
                <div className='row'>
                    <div className='col'>Analyst</div>
                    <div className='col'>Score</div>
                    <div className='col'>Multiplier</div>
                    <div className='col'>Points</div>
                </div>
                {dashboards}
            </MDBAccordionItem>
        )
    })
  
  
    const getTimeRemaining = () => {
        const t = getTime();
        // const total = Date.parse(e) - Date.parse((new Date()).toString());
        const nextStartTimes = startTimes.filter(x => x[1] == 'Week 3');
        let total = 0;
        if (nextStartTimes.length) {
            // total = nextStartTimes.map(x => x[0] - t).reduce((a, b) => Math.min(a, b), msInDay * 100);
            total = (1000 * 60 * 60 * 24 * 7) + nextStartTimes.map(x => x[0] - t).reduce((a, b) => Math.min(a, b), msInDay * 100);
        }

        // const total = Date.parse(e) - Date.parse((new Date()).toString());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        const days = Math.floor((total / msInDay));
        return {
            total, days, hours, minutes, seconds
        };
    }
  
  
    const updateTimer = () => {
        let { total, days, hours, minutes, seconds } = getTimeRemaining();
        if (total >= 0) {
            setTimer(
                (days > 9 ? days : '0' + days) + ':' +
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }
  
  
    const startTimer = () => {
        updateTimer();
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            updateTimer();
        }, 1000)
        // @ts-ignore
        Ref.current = id;
    }
  
    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible
  
    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
        startTimer();
    }, []);
  
    // Another way to call the clearTimer() to start
    // the countdown is via action event from the
    // button first we create function to be called
    // by the button
    const rows = places.map((x, i) => {
        const prize = prizes[x];
        const a = prize / mxPrize;
        return(
            // <tr style={{'backgroundColor': `rgba(99, 190, 123, ${a})`}}>
            <div key={i} className='row prize-row' style={{'backgroundColor': `rgba(99, 190, 123, ${a})`}}>
                <div className='col'>{x + 1}</div>
                <div className='col'>{prize > 0 ? `$${prize}` : `-`}</div>
            </div>
        )
        // return(
        //     <tr style={{'backgroundColor': `rgba(99, 190, 123, ${a})`}}>
        //         <td>{x + 1}</td>
        //         <td>{prize > 0 ? `$${prize}` : `-`}</td>
        //     </tr>
        // )
    })

	const weekOptions = ['Play-In','Week 1','Week 2','Week 3','Overall'].map((x, i) => {
        const valid = ['Play-In','Week 1','Week 2','Overall','Week 3']
		return(
			<Dropdown.Item disabled={!valid.includes(x)} key={i} eventKey={i} onClick={() => {
                // if (x == 'Play-In' && grouping == 'Team') {
                //     setGrouping('Analyst');
                // }
				setWeek(x);
			}}>
				<span>{x}</span>
			</Dropdown.Item>
		)
	})
	const groupingOptions = ['Analyst','Team'].map((x, i) => {
		return(
			<Dropdown.Item key={i} eventKey={i} onClick={() => {
                // if (x == 'Team' && week == 'Play-In') {
                //     setWeek('Week 1');
                // }
				setGrouping(x);
			}}>
				<span>{x}</span>
			</Dropdown.Item>
		)
	})
    const topRows = userPoints.slice(0, 3).map((x, i) => {
		const img = require(`../assets/avatars/${x.twitter_handle.toLowerCase()}.png`);
        return(
            <div className='col-12 col-lg-4 top-row'>
                <div className={`top-row-outer place_${i}`}>
                    <div className='top-row-inner'>
                        <img src={String(img)}></img>
                    </div>
                    <div className='username'>{x.flipside_handle}</div>
                    <div className='score'>{x.points.toLocaleString()}</div>
                </div>
            </div>
        )
    })
    const topRowsTeam = teamPoints.slice(0, 3).map((x, i) => {
		const img = require(`../assets/teams/${getTeamImg(x.team_name)}`);
        return(
            <div className='col-12 col-lg-4 top-row'>
                <div className={`top-row-outer place_${i}`}>
                    <div className='top-row-inner'>
                        <img src={String(img)}></img>
                    </div>
                    <div className='username'>{x.team_name}</div>
                    <div className='score'>{x.points.toLocaleString()}</div>
                </div>
            </div>
        )
    })
    const userHeader = <div className='row accordion-header-row'>
        <div style={{'textAlign': 'left'}} className='user-col col-2 col-lg-2'>Rank</div>
        <div className={`user-col col-5 col-lg-${isPlayIn ? 4 : 3}`}>
            Analyst
        </div>
        {
            isPlayIn ? null :
            <div className='user-col col col-lg-4'>
                Team
            </div>
        }
        <div className='user-col col-2 col-lg-1'>Points</div>
        <div className='user-col col-2 col-lg-1'>Prize</div>
    </div>
    const teamHeader = <div className='row accordion-header-row'>
        <div style={{'textAlign': 'left'}} className='user-col col-2 col-lg-1'>Rank</div>
        <div className='user-col col col-lg-7'>
            Team
        </div>
        <div className='user-col col-2 col-lg-1'>Points</div>
    </div>
    const displayNull = week == 'Play-In' && grouping == 'Team';
    // const timerStyle = showFilters ? {'float':'left'} : {'float':'left'};
    // const timerStyle = ;
    const t = getTime();
    return (
        <div className='score-page'>
            <div className=''>
                <div className='dropdown-row' style={{'position':'relative'}}>
                    {
                        showFilters ? 
                            <>
                                <div className='' style={{'position':'absolute', 'right':'100px', 'marginTop': '7px'}}>
                                    <Dropdown className='base-dropdown' as={ButtonGroup}>
                                        <Dropdown.Toggle id="week-dropdown">
                                            <span>{week}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="base-dropdown-menu">
                                            {weekOptions}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className='' style={{'position':'absolute', 'right':'0px', 'marginTop': '7px'}}>
                                    <Dropdown className='base-dropdown' as={ButtonGroup}>
                                        <Dropdown.Toggle id="grouping-dropdown">
                                            <span>{grouping}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="base-dropdown-menu">
                                            {groupingOptions}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </>
                        : null
                    }
                    <div className='timer'>
                        <div className='big-text'>{timer}</div>
                        <div className='small-text'>{`Time until Week 3 ${t > startTimes[4][0] ? 'Ends' : 'Ends'}`}</div>
                    </div>
                </div>
            </div>
            {/* <div style={{'paddingTop':'6px', 'fontSize':'12px', 'opacity': '0.85'}}></div> */}
            {/* <div style={{'paddingTop':'6px', 'fontSize':'12px', 'opacity': '0.85'}}>The 9 analysts that scored 1,000+ points in the previous <a target='_blank' href='https://science.flipsidecrypto.xyz/tournament/'>tournament</a> auto-qualify and the next 23 analysts with the highest scores qualify for the FPL</div> */}
            {
                isPlayIn ? 
                <div className='orange-text' style={{'fontSize':'12px', 'opacity': '0.8'}}><span>The top 33 from the play-in qualified for the Flipside Premier League</span></div>
                : null
            }
            
                {
                    displayNull ? <div className='no-team-scores'>No Team Scores for the Play-In</div>
                    :
                        <>
                            {/* <div className='medals-row row'>
                            {
                                grouping == 'Analyst' ? topRows : topRowsTeam
                            }
                            </div> */}
                            {
                                grouping == 'Analyst' ? userHeader : teamHeader
                            }
                            
                            <MDBAccordion className='fpl-accordion' initialActive={0}>
                            {
                                grouping == 'Analyst' ? userRowsAccordion : teamRowsAccordion
                            }
                            </MDBAccordion>
                        </>
                }
        </div>
	);
}

export default ScorePage;